


































import Vue from "vue";
import { SearchAnything } from "@/interfaces/recruiter/dashboard/search_anything";

export default Vue.extend({
  name: "SearchAnything",
  data(): SearchAnything {
    return {
      form: false,
      search: "",
      search_rules: [
        (value: string) =>
          !!value || this.$t("candidate.jobs.field-required-rule")
      ]
    };
  },
  methods: {
    submit_form() {
      // console.log("Searching ", this.search);
    }
  }
});
