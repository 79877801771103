

































import Vue from "vue";

export default Vue.extend({
  name: "IndividualIndustry",
  data() {
    return {
      font_color: "",
      background_color: "",
      color_code: ""
    };
  },
  mounted() {
    // const color = get_random_color(); // Get random color
    this.font_color = `color: ${this.color}`;
    this.background_color = `background: ${this.color}`;
    this.color_code = this.color;
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    applied: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  methods: {
    transform_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", this.color);
      return icon;
    }
  }
});
