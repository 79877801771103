






























































import Vue, { PropType } from "vue";
import VueApexCharts from "vue-apexcharts";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { EmiratisationStatsResponse } from "@/interfaces/responses/statistics";
export default Vue.extend({
  name: "GenderStatistics",
  components: {
    // DataNotFound
    "vue-apex-charts": VueApexCharts
  },
  data() {
    return {
      emiratisation_statistics_graph_series: [],
      emiratisation_statistics_graph_options: {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        tooltip: {
          enabled: false,
          position: "bottom"
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
            borderRadiusApplication: "end",
            barHeight: "20px",
            colors: {
              ranges: [
                {
                  from: 50,
                  to: 100,
                  color: "#FF8473"
                },
                {
                  from: 0,
                  to: 49,
                  color: "#EE49FD"
                }
              ]
            }
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: ["Male", "Female"],
          min: 0,
          max: 100,
          labels: {
            show: true,
            formatter: function (value: number) {
              return value + "%";
            },
            style: {
              colors: ["#334466"],
              fontSize: "12px",
              fontFamily: "Roboto",
              fontWeight: 400
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: ["#3F5073"],
              fontSize: "14px",
              fontFamily: "Roboto",
              fontWeight: 500
            }
          }
        },
        grid: {
          show: true,
          borderColor: "#ffffff",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        }
      }
    };
  },
  // props: {
  //   stats: {
  //     type: Object as PropType<EmiratisationStatsResponse>,
  //     required: true
  //   },
  //   stat_error: {
  //     type: Boolean,
  //     required: true
  //   }
  // },
  created() {
    setTimeout(() => {
      this.emiratisation_statistics_graph_series = [
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          name: "Emiratisation Statistics",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data: [57, 33]
        }
      ];
    }, 1000);
  }
});
