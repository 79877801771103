






































import Vue from "vue";

export default Vue.extend({
  name: "CandidateWithRating",
  props: {
    avatar: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    designation: {
      type: String,
      required: true
    },
    rating: {
      type: String,
      required: true
    }
  }
});
